import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { Link } from "gatsby"

import { useHeaderAssets } from "../hooks/header"
import Button from "./Button"
import SocialBanner from "./SocialBanner"

const StyledHeader = styled.header`
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
  position: ${props => (props.video ? "relative" : "absolute")};
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;

  @media all and (min-width: 768px) {
    background-image: ${props => `url(${props.bg})`};
    background-image: ${props => `url(${props.bg})`},
      linear-gradient(
        to bottom,
        rgba(0, 0, 0, 1) 50%,
        rgba(255, 255, 255, 0) 100%
      );
  }
`
const StyledLink = styled(Link)`
  display: inline-block;
  width: 100%;
  color: #fff;
  text-decoration: none;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: #6c9fff;
  }
`
const LogoContainer = styled.div`
  text-align: center;
  padding: 10px 0;
  margin: 0 auto;

  & ${StyledLink} {
    max-width: 175px;
    flex: 1;
  }
`
const Logo = styled(Img)``
const LinksContainer = styled.nav`
  margin: 25px auto;
  padding: 0 15px;
`
const Links = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media all and (min-width: 768px) {
    justify-content: center;
  }
`
const LinkElement = styled.li`
  font-family: "Teko", sans-serif;
  text-transform: uppercase;
  font-size: 1em;
  margin: 0;
  padding: 0 10px;
  max-width: 120px;

  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }

  @media all and (min-width: 768px) {
    padding: 0 25px;
    max-width: initial;
  }
`

const Header = ({ videoBg = false }) => {
  const { logo, bg } = useHeaderAssets()
  return (
    <StyledHeader bg={bg} video={videoBg} id="top">
      <LogoContainer>
        <StyledLink to="/">
          <Logo fluid={logo} alt="flip-unit-logo" />
        </StyledLink>
      </LogoContainer>
      <SocialBanner />
      <LinksContainer>
        <Links>
          <LinkElement>
            <StyledLink to="/sportbemutatok">
              Sportbemutatók, sportműsorok
            </StyledLink>
          </LinkElement>
          <LinkElement>
            <StyledLink to="/blog">Blog</StyledLink>
          </LinkElement>
          <LinkElement>
            <Button to="/ajanlatkeres">Ajánlatkérés</Button>
          </LinkElement>
        </Links>
      </LinksContainer>
    </StyledHeader>
  )
}

export default Header
