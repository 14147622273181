import React, { useLayoutEffect, useState } from "react"
import styled from "styled-components"

const UpButton = styled.a`
  position: fixed;
  bottom: 30px;
  right: 30px;
  color: #fff;
  background-color: #6c9fff;

  width: 40px;
  height: 40px;

  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  opacity: ${props => (props.show ? "1" : "0")};

  transition: background-color 0.3s ease-in-out, opacity 0.3s ease-in-out;

  &:hover {
    background-color: #8e9fff;
  }
`

const Up = () => {
  const [show, setShow] = useState(false)

  useLayoutEffect(() => {
    const onScroll = () => {
      const scrollPos = window.scrollY + window.innerHeight
      const innerHeight = window.innerHeight
      setShow(scrollPos > innerHeight)
    }

    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll)
  }, [])

  return (
    <UpButton href="#top" show={show}>
      &uarr;
    </UpButton>
  )
}

export default Up
