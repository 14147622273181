import { useStaticQuery, graphql } from "gatsby"

export const useAboutAssets = () => {
  const images = useStaticQuery(
    graphql`
      query AboutImages {
        dave: file(relativePath: { eq: "flipunit__dave-foto@2x.png" }) {
          src: childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        international: file(
          relativePath: { eq: "flipunit__icon__nemzetkozi@2x.png" }
        ) {
          src: childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        professional: file(
          relativePath: { eq: "flipunit__icon__professzionalis@2x.png" }
        ) {
          src: childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        reality: file(relativePath: { eq: "flipunit__icon__valosag@2x.png" }) {
          src: childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        hand: file(relativePath: { eq: "flipunit__Alkar.psd@2x.png" }) {
          src: childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        bg: file(relativePath: { eq: "flipunit__hero__bg-poligon2@2x.png" }) {
          src: childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              src
            }
          }
        }
      }
    `
  )
  return {
    dave: images.dave.src.fluid,
    international: images.international.src.fluid,
    professional: images.professional.src.fluid,
    reality: images.reality.src.fluid,
    bg: images.bg.src.fluid.src,
    hand: images.hand.src.fluid,
  }
}
