import { useStaticQuery, graphql } from "gatsby"

export const useSiteMeta = () => {
  const {
    site: { siteMetadata },
  } = useStaticQuery(
    graphql`
      query SiteMeta {
        site {
          siteMetadata {
            phone
            email
            address
            tax
            url
            title
            facebook
            youtube
            instagram
            linkedin
            whatsapp
          }
        }
      }
    `
  )

  return {
    ...siteMetadata,
  }
}
