import { useStaticQuery, graphql } from "gatsby"

export const useLogos = () => {
  const images = useStaticQuery(
    graphql`
      query Logos {
        allFile(
          filter: { absolutePath: { regex: "/logok/" } }
          sort: { order: ASC, fields: childImageSharp___fluid___originalName }
        ) {
          edges {
            node {
              src: childImageSharp {
                fluid(maxWidth: 195) {
                  originalName
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `
  )

  return (
    images.allFile.edges
      .map(image => ({
        src: image.node.src.fluid,
        alt: image.node.src.fluid.originalName.split("__")[1].split(".")[0],
      }))
      // .sort((a, b) =>
      //   a.originalName.localeCompare(b.originalName, undefined, {
      //     numeric: true,
      //     sensitivity: "base",
      //   })
      // )
      .reduce((acc, i, idx) => {
        return !(idx % 6)
          ? [...acc, [i]]
          : [...acc, [...acc[acc.length - 1], i]]
      }, [])
      .filter(logos => logos.length === 6)
  )
}
