import { useStaticQuery, graphql } from "gatsby"

export const usePartnersAssets = () => {
  const images = useStaticQuery(
    graphql`
      query PartnerImages {
        bg: file(relativePath: { eq: "flipunit__bg__megbizok@2x.jpg" }) {
          src: childImageSharp {
            fluid(maxWidth: 1920) {
              src
            }
          }
        }
        leftRibbon: file(
          relativePath: {
            eq: "flipunit__asset__megbizo__ribbon-hide-left@2x.png"
          }
        ) {
          src: childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        rightRibbon: file(
          relativePath: {
            eq: "flipunit__asset__megbizo__ribbon-hide-right@2x.png"
          }
        ) {
          src: childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return {
    bg: images.bg.src.fluid.src,
    leftRibbon: images.leftRibbon.src.fluid,
    rightRibbon: images.rightRibbon.src.fluid,
  }
}
