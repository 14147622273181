import { Link } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import styled from "styled-components"

import LoveFooter from "../../../images/love-footer.svg"
import GoldInsta from "../../../images/footer-gold-insta.svg"
import GoldLinkedin from "../../../images/footer-gold-linkedin.svg"
import GoldMail from "../../../images/footer-gold-mail.svg"
import GoldWhatsapp from "../../../images/footer-gold-whatsapp.svg"
import GoldYoutube from "../../../images/footer-gold-yt.svg"
import { pxToRem } from "../../../utils"
import Container from "../../Container"
import { useHeaderAssets } from "../../../hooks/header"
import { useSiteMeta } from "../../../hooks/siteMeta"

const FooterWrapper = styled.footer`
  color: #fff;
  background-color: #000;
  padding: 40px 0;
`
const SocialIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: center;

  @media all and (min-width: 768px) {
    justify-self: flex-end;
  }
`
const SocialLink = styled.a`
  display: flex;
  width: 25px;
  height: 25px;
  margin: 0 10px;

  & .cls-1 {
    ${props => (props.color ? `fill: ${props.color}}` : ``)}
  }
`
const Row = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: 1fr;
  grid-row-gap: ${pxToRem(30)};

  @media all and (min-width: 768px) {
    justify-content: space-around;
    grid-template-columns: 3fr 1fr 3fr;
  }
`
const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @media all and (min-width: 1025px) {
    display: block;
  }
`
const StyledLink = styled(Link)`
  font-size: ${pxToRem(16)};
  font-weight: 400;
  color: #fff;
  text-decoration: none;
  text-align: center;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: #29abe2;
  }

  @media all and (min-width: 768px) {
    padding-right: 20px;
    text-align: left;

    &:last-child {
      padding-right: 0;
    }
  }
`
const Footnote = styled.span`
  font-size: 1rem;
  grid-column: 1 / span 3;
  text-align: center;
  font-weight: 400;
`
const LoveIcon = styled(LoveFooter)`
  padding-top: 5px;
  width: 1rem;
  height: 1rem;
`

const Footer = ({ color }) => {
  const { logo } = useHeaderAssets()
  const {
    phone,
    email,
    address,
    tax,
    facebook,
    instagram,
    linkedin,
    youtube,
    whatsapp,
  } = useSiteMeta()
  return (
    <FooterWrapper>
      <Container>
        <Row style={{ marginBottom: 30 }}>
          <LinkWrapper>
            <StyledLink
              to="/adatvedelmi-iranyelvek"
              className="wow animate__fadeIn"
            >
              Adatvédelmi irányelvek
            </StyledLink>
            {/* <StyledLink
              to="/terms-and-conditions"
              className="wow animate__fadeIn"
            >
              Terms
            </StyledLink> */}
            <StyledLink to="/impresszum" className="wow animate__fadeIn">
              Impresszum
            </StyledLink>
          </LinkWrapper>
          <Img
            fluid={logo}
            className="wow fadeIn"
            alt="flip-unit-logo"
            style={{ maxWidth: 200, width: "100%", margin: "0 auto" }}
            data-wow-delay=".2s"
          />
          <SocialIcons>
            <SocialLink
              color={color}
              href={`mailto:${email}`}
              className="wow animate__fadeIn"
            >
              <GoldMail />
            </SocialLink>
            <SocialLink
              color={color}
              href={linkedin}
              target="__blank"
              rel="nofollow noindex"
              className="wow animate__fadeIn"
              data-wow-delay=".2s"
            >
              <GoldLinkedin />
            </SocialLink>
            <SocialLink
              color={color}
              href={youtube}
              target="__blank"
              rel="nofollow noindex"
              className="wow animate__fadeIn"
              data-wow-delay=".4s"
            >
              <GoldYoutube />
            </SocialLink>
            <SocialLink
              color={color}
              href={instagram}
              target="__blank"
              rel="nofollow noindex"
              className="wow animate__fadeIn"
              data-wow-delay=".6s"
            >
              <GoldInsta />
            </SocialLink>
            {/* <SocialLink
              color={color}
              href={whatsapp}
              target="__blank"
              rel="nofollow noindex"
              className="wow animate__fadeIn"
              data-wow-delay=".8s"
            >
              <GoldWhatsapp />
            </SocialLink> */}
          </SocialIcons>
        </Row>
        <Row>
          <Footnote className="wow animate__fadeIn">
            Minden jog fenntartva! © {new Date().getFullYear()}
            {/* Made &middot;
            with <LoveIcon /> by extreme. */}
          </Footnote>
        </Row>
      </Container>
    </FooterWrapper>
  )
}

export default Footer
