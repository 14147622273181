import styled from "styled-components"
import { Link } from "gatsby"

const Button = styled(Link)`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  text-decoration: none;
  background-color: transparent;
  font-family: "Teko", sans-serif;
  color: #fff;
  padding: 5px 10px 2px 10px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  z-index: 1;

  &:before {
    position: absolute;
    display: block;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    clip-path: polygon(
      0 0,
      100% 0,
      100% calc(100% - 10px),
      calc(100% - 10px) 100%,
      0 100%,
      0 2px,
      2px 2px,
      2px calc(100% - 2px),
      calc(100% - 11px) calc(100% - 2px),
      calc(100% - 2px) calc(100% - 11px),
      calc(100% - 2px) 2px,
      0 2px
    );
    background: #fff;
    z-index: -1;
    opacity: 1;
  }
  &:after {
    position: absolute;
    display: block;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    clip-path: polygon(
      0 0,
      100% 0,
      100% calc(100% - 10px),
      calc(100% - 10px) 100%,
      0 100%
    );
    background: #fff;
    opacity: 0;
    z-index: -1;
    transition: opacity 0.2s ease-in-out;
  }
  &:hover {
    color: #000;
    animation-fill-mode: forwards;

    &:before {
      animation: turn-on--outline-before 1s;
    }
    &:after {
      opacity: 1;
      animation-fill-mode: forwards;
    }
  }
`

export default Button
