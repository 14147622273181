import { useStaticQuery, graphql } from "gatsby"

export const useHeroAssets = () => {
  const images = useStaticQuery(
    graphql`
      query HeroImages {
        bg: file(relativePath: { eq: "flipunit__hero__bg.psd@2x.jpg" }) {
          src: childImageSharp {
            fluid(maxWidth: 2560) {
              src
            }
          }
        }
        rightPoligon: file(
          relativePath: { eq: "hero__poligon-tri__l@2x.png" }
        ) {
          src: childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        leftPoligon: file(relativePath: { eq: "hero__poligon-tri__r@2x.png" }) {
          src: childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        guy: file(relativePath: { eq: "flipunit__hero__img2@2x.png" }) {
          src: childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        girl: file(relativePath: { eq: "flipunit__hero__img1@2x.png" }) {
          src: childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        arrow: file(
          relativePath: { eq: "flipunit__hero__arrow__down@2x.png" }
        ) {
          src: childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        showBg: file(relativePath: { eq: "flipunit__listaoldal__cover.jpg" }) {
          src: childImageSharp {
            fluid(maxWidth: 1920) {
              src
            }
          }
          resize: childImageSharp {
            fixed(width: 1200, quality: 90) {
              src
            }
          }
        }
        mediaVideo: file(relativePath: { eq: "flipunit__cover__media.mp4" }) {
          publicURL
        }
        collection: file(relativePath: { eq: "flipunit__montazs.jpeg" }) {
          src: childImageSharp {
            fluid(maxWidth: 2560) {
              src
            }
          }
        }
      }
    `
  )

  return {
    hero: images.bg.src.fluid.src,
    collection: images.collection.src.fluid.src,
    rightPoligon: images.rightPoligon.src.fluid,
    leftPoligon: images.leftPoligon.src.fluid,
    guy: images.guy.src.fluid,
    girl: images.girl.src.fluid,
    arrow: images.arrow.src.fluid,
    showBg: images.showBg.src.fluid.src,
    showBgResize: images.showBg.resize.fixed.src,
    mediaVideo: images.mediaVideo.publicURL,
  }
}
