import { useStaticQuery, graphql } from "gatsby"

export const useHeaderAssets = () => {
  const images = useStaticQuery(
    graphql`
      query HeaderImages {
        logo: file(relativePath: { eq: "flipunit__logo@2x.png" }) {
          src: childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        bg: file(relativePath: { eq: "flipunit__bg__header-menu@2x.png" }) {
          src: childImageSharp {
            fluid(maxWidth: 1920) {
              src
            }
          }
        }
        bghero: file(relativePath: { eq: "flipunit__hero__bg.psd@2x.jpg" }) {
          src: childImageSharp {
            fluid(maxWidth: 1920) {
              src
            }
          }
        }
      }
    `
  )

  return {
    logo: images.logo.src.fluid,
    bg: images.bg.src.fluid.src,
    hero: images.bghero.src.fluid.src,
  }
}
