import React from "react"
import Consent from "react-cookie-consent"
import { Link } from "gatsby"
import styled from "styled-components"

const StyledLink = styled(Link)`
  color: #fff;
  text-decoration: underline;
`

const Button = styled.button`
  background: transparent !important;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  text-decoration: none;
  background-color: transparent !important;
  font-family: "Teko", sans-serif;
  color: #000;
  padding: 5px 10px 2px 10px !important;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  z-index: 1;

  &.decline {
    background: transparent;
    color: #fff !important;

    &:after {
      opacity: 0;
    }
  }

  &:before {
    position: absolute;
    display: block;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    clip-path: polygon(
      0 0,
      100% 0,
      100% calc(100% - 10px),
      calc(100% - 10px) 100%,
      0 100%,
      0 2px,
      2px 2px,
      2px calc(100% - 2px),
      calc(100% - 11px) calc(100% - 2px),
      calc(100% - 2px) calc(100% - 11px),
      calc(100% - 2px) 2px,
      0 2px
    );
    background: #fff;
    z-index: -1;
    opacity: 1;
  }
  &:after {
    position: absolute;
    display: block;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    clip-path: polygon(
      0 0,
      100% 0,
      100% calc(100% - 10px),
      calc(100% - 10px) 100%,
      0 100%
    );
    background: #fff;
    opacity: 1;
    z-index: -1;
    transition: opacity 0.2s ease-in-out;
  }
  &:hover {
    color: #000 !important;
    animation-fill-mode: forwards;

    &:before {
      animation: turn-on--outline-before 1s;
    }
    &:after {
      opacity: 1;
      animation-fill-mode: forwards;
    }
  }
`

const CookieConsent = () => {
  return (
    <Consent
      location="bottom"
      buttonText="Elfogadom"
      declineButtonText="Nem fogadom el"
      cookieName="flip-gdpr"
      enableDeclineButton
      style={{
        background: "#6c9fff",
        fontSize: "0.9em",
        padding: "5px 15px",
        alignItems: "center",
        lineHeight: 1.6,
      }}
      declineButtonClasses="decline"
      ButtonComponent={Button}
    >
      Weboldalunk sütiket használ a tartalmak és hírdtések személyre szabásához,
      közösségi funkciók biztosításához és forgalmunk elemzéséhez.{" "}
      <StyledLink to="/adatvedelmi-iranyelvek">Itt</StyledLink> bővebb
      információkat tudhatsz meg a használatukról.
    </Consent>
  )
}

export default CookieConsent
