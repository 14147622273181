import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { useSwipeable } from "react-swipeable"

import Container from "../Container"
import LeftArrow from "../../images/flipunit__icon__left-arrow__white.svg"
import RightArrow from "../../images/flipunit__icon__right-arrow__white.svg"
import EmptyTriangle from "../../images/flipunit__icon__bullet-empty__white.svg"
import FilledTriangle from "../../images/flipunit__icon__bullet__white.svg"
import { usePartnersAssets } from "../../hooks/partners"
import { useLogos } from "../../hooks/logos"

const Section = styled.section`
  padding: 80px 0 70px 0;
  background-color: #1a1a1a;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: ${props => `url(${props.bg})`};
`
const SectionTitle = styled.h2`
  font-size: 2.22222223em;
  text-align: center;
  margin-bottom: 4.16666667rem;
  line-height: 0.9;
  color: #000;
`
const Accent = styled.span`
  color: #6c9fff;
`
const SliderContainer = styled.div`
  position: relative;
  background-color: #fff;
  display: flex;
  overflow: hidden;
  margin-bottom: 2.77777778rem;
  padding: 10px 0;
`
const Image = styled(Img)``
const Slide = styled.div`
  display: grid;
  min-width: 100%;
  justify-items: center;
  ${props =>
    props.position ? `transform: translateX(${props.position}%)` : ""};
  transition: transform 0.5s ease;
  will-change: transform;
  grid-template-columns: repeat(2, calc(100% / 2));

  & ${Image} {
    width: 100%;
  }

  @media all and (min-width: 768px) {
    grid-template-columns: repeat(6, calc(100% / 6));
  }
`
const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    width: 15px;
    margin: 0 10px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
    .cls-1 {
      fill: #000;
    }
  }
`
const StyledEmptyTriangle = styled(EmptyTriangle)``
const StyledFilledTriangle = styled(FilledTriangle)``
const IconContainer = styled.div`
  width: 32px;
  height: 32px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  & ${StyledEmptyTriangle} {
    ${props => (props.active ? "transform: rotate(180deg)" : "")};
    transition: transform 0.5s ease;
    will-change: transform;
  }
`

const Partners = () => {
  const { bg, leftRibbon, rightRibbon } = usePartnersAssets()
  const [visibleId, setVisibleId] = useState(0)
  const partners = useLogos()
  const maxLength = partners.length

  useEffect(() => {
    const interval = setInterval(() => next(), 8000)
    return function cleanup() {
      clearInterval(interval)
    }
  })

  const prev = () => {
    if (visibleId - 1 < 0) {
      setVisibleId(maxLength - 1)
    } else {
      setVisibleId(visibleId - 1)
    }
  }
  const next = () => {
    if (visibleId + 1 >= maxLength) {
      setVisibleId(0)
    } else {
      setVisibleId(visibleId + 1)
    }
  }
  const goTo = id => () => setVisibleId(id)
  const handlers = useSwipeable({
    onSwipedLeft: next,
    onSwipedRight: prev,
    trackTouch: true,
    trackMouse: true,
  })
  return (
    <Section bg={bg}>
      <Container style={{ cursor: "pointer" }} {...handlers}>
        <SectionTitle className="wow fadeIn">
          <Accent>Kiemelt</Accent> partnereink
        </SectionTitle>
        <SliderContainer>
          {partners.map((partner, idx) => (
            <Slide key={idx} position={visibleId * -100}>
              {partner.map((image, idx) => (
                <Image
                  fluid={image.src}
                  key={idx}
                  alt={image.alt}
                  title={image.alt}
                />
              ))}
            </Slide>
          ))}
        </SliderContainer>
        <Pagination className="wow fadeIn">
          <IconContainer onClick={prev}>
            <LeftArrow />
          </IconContainer>
          {partners.map((r, idx) => (
            <IconContainer
              key={idx}
              onClick={goTo(idx)}
              active={idx === visibleId}
            >
              <StyledEmptyTriangle />
            </IconContainer>
          ))}
          <IconContainer onClick={next}>
            <RightArrow />
          </IconContainer>
        </Pagination>
      </Container>
    </Section>
  )
}

export default Partners
