import React from "react"
import styled from "styled-components"
import { FaYoutube } from "react-icons/fa"
import { FaFacebookSquare } from "react-icons/fa"
import { FaInstagram } from "react-icons/fa"
import { useSiteMeta } from "../hooks/siteMeta"

const SocialContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 175px 1fr;
`
const StyledHr = styled.div`
  width: 100%;
  height: 5px;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  margin: auto 0;
`
const Socials = styled.div`
  color: #fff;
  font-size: 1em;
  padding: 5px 0;
  display: flex;
  justify-content: space-evenly;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
`
const SocialLink = styled.a`
  color: #fff;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
  display: flex;

  &:hover {
    color: #6c9fff;
  }
`

const SocialBanner = () => {
  const { facebook, instagram, youtube } = useSiteMeta()
  return (
    <SocialContainer>
      <StyledHr />
      <Socials>
        <SocialLink href={facebook} rel="noreferrer noopener" target="_blank">
          <FaFacebookSquare />
        </SocialLink>
        <SocialLink href={instagram} rel="noreferrer noopener" target="_blank">
          <FaInstagram />
        </SocialLink>
        <SocialLink href={youtube} rel="noreferrer noopener" target="_blank">
          <FaYoutube />
        </SocialLink>
      </Socials>
      <StyledHr />
    </SocialContainer>
  )
}

export default SocialBanner
