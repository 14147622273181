export const pxToRem = (px, fontSize = 16) => `${px / fontSize}rem`

export function validate(
  { firstName, lastName, email, description, terms, company, event },
  setError
) {
  return new Promise((resolve, reject) => {
    const emailRegexp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
    let errors = {}
    if (!firstName) errors = { ...errors, firstName: "First name is required!" }
    if (!lastName) errors = { ...errors, lastName: "Last name is required!" }
    if (!company) errors = { ...errors, company: "Company is required!" }
    if (!event) errors = { ...errors, event: "Event is required!" }
    if (!email) {
      errors = { ...errors, email: "Email is required!" }
    } else if (!emailRegexp.test(email)) {
      errors = { ...errors, email: "Email format is invalid!" }
    }
    if (!description)
      errors = { ...errors, description: "Description is required!" }
    if (!terms)
      errors = {
        ...errors,
        terms: "You must accept the terms and the privacy policy!",
      }
    setError(errors)
    Object.keys(errors).length === 0 ? resolve() : reject()
  })
}

export function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}
